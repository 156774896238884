<template>
  <div v-if="state.dialog">
    <v-dialog v-model="state.dialog" :persistent="true" width="723">
      <v-card class="modal">
        <v-card-title class="black-1--text">
          Deseja encaminhar os itens selecionados para a área designada?
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <p class="my-5">
            Os itens ficarão pendentes na área do estoque até serem liberados
            para a desmanufatura ou para o recondicionamento.
          </p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex flex-wrap justify-space-between">
          <v-btn
            class="mt-1 cancel-button black-3--text"
            color="#ffffff"
            :block="$isMobile"
            @click="state.dialog = false"
            >Cancelar</v-btn
          >
          <v-btn
            type="button"
            color="secondary-color-1"
            :block="$isMobile"
            class="ma-0 mt-1 next-step-button white--text"
            @click="handleConfirm"
            >Confirmar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    name: "ConfirmDestiny",

    props: {
      state: {
        type: Object,
        required: true
      },
      fnConfirm: {
        type: Function,
        required: true
      }
    },

    methods: {
      handleConfirm() {
        this.state.dialog = false;
        this.fnConfirm();
      }
    }
  };
</script>

<style></style>
