<template>
  <v-dialog v-model="state.active" scrollable :persistent="true" width="488">
    <v-card class="modal">
      <v-card-title class="modal-title">Deseja excluir esse item</v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <div class="pt-5">
          <p>
            Tem certeza que deseja excluir este item da lote?
          </p>
        </div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="d-flex justify-space-between">
        <v-btn
          class="cancel-button black-3--text"
          color="#ffffff"
          @click="state.active = false"
          >Cancelar</v-btn
        >
        <v-btn
          type="button"
          @click="$emit('delete-item', true)"
          color="secondary-color-1"
          class="next-step-button white--text"
          >Excluir</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { hasKeys } from "@/modules/core/helpers/objectHelpers";

  export default {
    name: "ConfirmDeleteDialog",

    props: {
      state: {
        type: Object,
        required: true,
        validator: obj => hasKeys(obj, ["active"])
      }
    }
  };
</script>

<style lang="scss" scoped></style>
