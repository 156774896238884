<template>
  <v-menu bottom origin="center center" transition="scale-transition">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        class="py-7"
        color="secondary-color-1 white--text"
      >
        <v-icon>
          mdi-download
        </v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item-group>
        <v-list-item
          class="item-dropdown"
          v-for="(item, i) in reportOptions"
          :key="i"
          @click="$emit(item.actionType)"
        >
          <v-list-item-icon>
            <v-icon class="item-dropdown-icon" v-text="item.icon" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.text" />
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
  export default {
    name: "BtnReport",
    data: () => ({
      reportOptions: [
        { text: "PDF", icon: "mdi-file-chart", actionType: "pdfReport" },
        { text: "Excel", icon: "mdi-file-excel", actionType: "excelReport" },
        {
          text: "CSV",
          icon: "mdi-file-delimited-outline",
          actionType: "csvReport"
        }
      ]
    })
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/stylesheets/styleguide";

  .item-dropdown:hover {
    background-color: $white-1;

    .item-dropdown-icon {
      color: $primary-color-2;
    }
  }
</style>
