<template>
  <div>
    <v-menu transition="slide-x-transition" bottom right>
      <template v-slot:activator="{ on, attrs }">
        <v-icon v-bind="attrs" v-on="on">
          mdi-dots-horizontal
        </v-icon>
      </template>

      <v-list>
        <v-list-item
          @click="handleOptionDeleteScreeningItem"
          class="d-flex align-center item-dropdown-user"
        >
          <v-list-item-title>
            <v-icon class="item-dropdown-icon">mdi-delete</v-icon>
            <span>Excluir</span>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <confirm-delete-dialog
      @delete-item="handleDeleteItem"
      :state="deleteDialogState"
    />
  </div>
</template>

<script>
  import ConfirmDeleteDialog from "@/modules/stock/batch/views/components/screening-items/ConfirmDeleteDialog";

  export default {
    name: "MenuItem",

    props: {
      id: {
        type: Number,
        require: true
      },
      fnConfirmDelete: {
        type: Function,
        required: true
      }
    },

    components: {
      ConfirmDeleteDialog
    },

    data: () => ({
      deleteDialogState: {
        active: false
      }
    }),

    methods: {
      handleOptionDeleteScreeningItem() {
        this.deleteDialogState.active = true;
      },

      handleDeleteItem() {
        this.deleteDialogState.active = false;
        this.fnConfirmDelete(this.id);
      }
    }
  };
</script>

<style lang="scss" scoped></style>
